var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main_box" }, [
    _c("div", { staticClass: "title" }, [
      _c("span", [
        _vm._v(
          _vm._s(_vm._f("equipmentTypeName")(_vm.dataSource.thirdEquipmentType))
        ),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "equipmentLists" },
      _vm._l(_vm.dataSource.thirdEquipmentList, function (item, index) {
        return _c("div", { key: index, staticClass: "equipmentList" }, [
          _c("div", { staticClass: "equipmentDetail" }, [
            _c("p", { staticClass: "name" }, [
              _vm._v(_vm._s(item.equipmentName)),
            ]),
            _c(
              "p",
              {
                staticClass: "status",
                class: item.equipmentStatus ? "green" : "red",
              },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm._f("equipmentStatus")(item.equipmentStatus)) +
                    " "
                ),
              ]
            ),
            _c("p", { staticClass: "time" }, [
              _vm._v(_vm._s(item.updatedTime)),
            ]),
          ]),
          _c("div", [
            _c("img", {
              attrs: {
                width: "48px",
                height: "50px",
                src: _vm._f("equipmentImage")(
                  _vm.dataSource.thirdEquipmentType
                ),
                alt: "",
              },
            }),
          ]),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }